import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Masonry } from 'masonic'
import { Button, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'

import '../components/index.scss'
import { ConditionListItemCondition, conditionsSelector, ConditionsState, fetchConditionListAction } from '../hasura/slices/conditions'
import { slackMessageAction } from '../hasura/slices/users'

// @ts-ignore
import logo from '../lib/images/logo.png'
// @ts-ignore
import questionMark from '../lib/images/question-blue.svg'

interface Props {
  index: number
  data: {
    category: string
    conditions: ConditionListItemCondition[]
  }
  width: any
}

const CategoryCard = (props: Props) => {
  return (
    <div className="text-m border rounded p-3 bw-2 border--black">
      <p className="bold text-l mb-1">{props.data.category}</p>

      <div style={{ gap: '3px' }} className="d-flex flex-column">
        {props.data.conditions.map((condition) => {
          return (
            <div className="d-flex align-items-center gap-5px" key={condition.display_name}>
              <p className="m-0">{condition.display_name}</p>
              {condition.notes && (
                <div>
                  <img id={`condition-${condition.id}`} src={questionMark} alt="question mark" className="icon-xxs pointer" />
                  <UncontrolledTooltip
                    arrowClassName="tooltip-arrow-light"
                    fade={false}
                    placement="bottom"
                    innerClassName="tooltip-inner-light dark-bg max-width-350px"
                    target={`condition-${condition.id}`}
                  >
                    {condition.notes}
                  </UncontrolledTooltip>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
const defaultFeedbackMessage = "Hey, I'd like to request a condition. What about..."

export default function CurrentConditionList() {
  const dispatch = useDispatch()

  const { conditionList }: ConditionsState = useSelector(conditionsSelector)

  const [modalOpen, setModalOpen] = useState(false)
  const [feedbackSent, setFeedbackSent] = useState(false)
  const [feedbackMessage, setFeedbackMessage] = useState('')

  useEffect(() => {
    dispatch(fetchConditionListAction())
  }, [])

  const handleSubmitFeedback = () => {
    dispatch(slackMessageAction('users', feedbackMessage))
    setModalOpen(false)
    setFeedbackMessage('')
    setFeedbackSent(true)
  }

  const openModal = () => {
    setFeedbackMessage(defaultFeedbackMessage)
    setModalOpen(true)
  }

  if (!conditionList) return null

  return (
    <div className="w-100 min-height-100vh p-5">
      <div className="max-width-1100px" style={{ margin: 'auto' }}>
        <div className="d-flex flex-column align-items-center justify-content-center gap-2 mb-4">
          <div className="d-flex align-items-center gap-10px mb-2">
            <img src={logo} alt="Radimal Logo" className="width-40px h-auto" />
            <h1 className="text-xxl bold text-center m-0">Radimal</h1>
          </div>
          <p className="text-l text--gray6 text-center m-0">Current Condition List | {moment().format('MMMM D, YYYY')}</p>
        </div>

        <Masonry rowGutter={25} columnGutter={25} columnWidth={275} maxColumnCount={3} items={conditionList} render={CategoryCard} />

        {feedbackSent ? (
          <p className="text-l text--success mb-0 w-100 semibold text-center">Thank you for your feedback!</p>
        ) : (
          <div className="rounded p-3 bg--gray1 mb-3 width-fit-content w-100 text-center">
            <p className="text-l text--gray7 bold mb-1">Suggest Condition</p>
            <p className="text-s text--gray6 m-0">What do you want to see?</p>
            <Button size="sm" color="dark" className="mt-3 max-width-300px w-100" onClick={openModal}>
              Suggest Condition
            </Button>
          </div>
        )}

        <Modal centered fade={false} isOpen={modalOpen} toggle={() => setModalOpen(false)}>
          <ModalHeader toggle={() => setModalOpen(false)}>
            <p className="text-l bold regular m-0">What seems to be the issue?</p>
            <p className="text-xs text--gray6 regular m-0">We'll let you know when we've resolved it.</p>
          </ModalHeader>
          <ModalBody>
            <Input
              type="textarea"
              value={feedbackMessage}
              onChange={(e) => setFeedbackMessage(e.target.value)}
              placeholder="Please enter your feedback..."
              rows={4}
              autoFocus
            />
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>

            <Button
              color="primary"
              onClick={handleSubmitFeedback}
              disabled={feedbackMessage === defaultFeedbackMessage || !feedbackMessage}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  )
}
